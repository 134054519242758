import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { StarPageHeader } from "../../components/PageHeader"
import GiftAnimation from "../../components/GiftAnimation"
import { PageComponent } from "../../components/PageComponent"
import LoadingComponent from "../../components/LoadingComponent"
import StarNavBar from "../../components/StarNavbar"

function checkURLHash(
  selectedGiftCard,
  selectedPrice,
  selectedGreetingCard,
  selectedMessage,
  selectedWrapper,
  setPageNotFound,
  setGiftDetails,
  setGiftCard,
  setGiftWrapper,
  setGreetingCard,
  setGreetingMessage
) {
  const data = {
    selectedGiftCard,
    selectedPrice,
    selectedGreetingCard,
    selectedMessage,
    selectedWrapper,
  }
  const serverURL = `${process.env.GATSBY_STAR_BACKEND_API}/api/v1/preview/`
  fetch(serverURL, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      }
      throw Error("Invalid URL")
    })
    .then(result => {
      const giftDetails = result
      setGiftDetails(giftDetails)
      if (giftDetails) {
        setGiftCard({
          brand_name: giftDetails.brand_name,
          product_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.product_image}`,
          redemption_instructions: giftDetails.redemption_instructions,
        })
        if (giftDetails.experience.status) {
          setGreetingCard(
            giftDetails.experience.greeting_card
              ? {
                  product_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.experience.greeting_card.front_image}`,
                  product_inner_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.experience.greeting_card.inner_image}`,
                }
              : null
          )
          setGreetingMessage(
            (giftDetails.experience.greeting_card &&
              giftDetails.experience.greeting_message) ||
              ""
          )
          setGiftWrapper(
            giftDetails.experience.gift_wrapper
              ? {
                  product_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.experience.gift_wrapper.product_image}`,
                }
              : null
          )
        }
      } else {
        setPageNotFound(true)
      }
    })
    .catch(error => {
      console.log(error)
      setPageNotFound(true)
    })
}

const SpaceDiv = styled.div`
  width: 100%;
  height: 2rem;
`

export default function PreviewPage(props) {
  const [pageNotFound, setPageNotFound] = useState(false)
  const [giftDetails, setGiftDetails] = useState({ test: 1 })
  const [giftcard, setGiftCard] = useState(null)
  const [giftwrapper, setGiftWrapper] = useState(null)
  const [greetingcard, setGreetingCard] = useState(null)
  const [greetingMessage, setGreetingMessage] = useState(null)

  useEffect(() => {
    if (props.location.search) {
      const urlHash = props.location.search
      console.log(urlHash)
      const paramsList = new URLSearchParams(urlHash.replace("?", ""))
      const selectedGiftCard = paramsList.get("c")
      const selectedPrice = paramsList.get("p")
      const selectedGreetingCard = paramsList.get("g")
      let selectedMessage = ""
      let selectedWrapper = ""
      if (paramsList.has("m")) {
        selectedMessage = paramsList.get("m")
      }
      if (paramsList.has("w")) {
        selectedWrapper = paramsList.get("w")
      }

      checkURLHash(
        selectedGiftCard,
        selectedPrice,
        selectedGreetingCard,
        selectedMessage,
        selectedWrapper,
        setPageNotFound,
        setGiftDetails,
        setGiftCard,
        setGiftWrapper,
        setGreetingCard,
        setGreetingMessage
      )
    }
  }, [])

  return (
    <>
      <StarNavBar />
      <SpaceDiv />
      <PageComponent className="received-gift-page">
        {!giftcard ? (
          <>
            {!pageNotFound ? (
              <LoadingComponent />
            ) : (
              window.location.replace("https://giftcards.star.com.au/")
            )}{" "}
          </>
        ) : (
          <>
            <StarPageHeader giftDetails={giftDetails} />
            <GiftAnimation
              giftDetails={giftDetails}
              giftcard={giftcard}
              giftwrapper={giftwrapper}
              greetingcard={greetingcard}
              greetingMessage={greetingMessage}
            />
          </>
        )}
      </PageComponent>
    </>
  )
}
