import React from "react"
import styled from "styled-components"
import StarLogo from "../assets/images/Entertainment_Group_Stacked_Gold.svg"

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  height: 75px;
  border-bottom: 2px solid #c9ab5d;
  padding: 1rem 0;
  margin-bottom: 2rem;
`

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 350px;
`

export default function StarNavBar(props) {
  return (
    <Nav>
      <LogoImage src={StarLogo} alt="Star Entertainment Group"></LogoImage>
    </Nav>
  )
}
